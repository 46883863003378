import React, { Component } from 'react'
import Auxiliar from './../../cao/Auxiliar'
import estilos from './Footer.module.css'

export default class componentName extends Component {
    render() {
        return (
            <Auxiliar>
                <footer className={estilos.footer}>
                    <p className={estilos.parrafo}>COPYRIGHT  ©2019 POWERED BY FUTURO DIGITAL</p>
                </footer>
            </Auxiliar>
        )
    }
}
