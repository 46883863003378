import React, { Component } from 'react'
import Auxiliar from '../cao/Auxiliar';
import { RouterGuard } from 'react-router-guard';
import configuracion from '../guard/configuracion';
import Fondo from '../components/Fondo/Fondo'
import Footer from '../components/Footer/Footer';
import BarraMenuMDB from '../components/BarraMenuMDB/BarraMenuMDB';



export default class Layout extends Component {
   
  render() {

    

     return (

      <Auxiliar>    
        <BarraMenuMDB></BarraMenuMDB> 
          <Fondo/>
          <RouterGuard config={configuracion} />
          <br></br>
          <br></br>
          <Footer/>
      </Auxiliar>
    )
  }
}
