import React from 'react'
import './Fondo.css';
// import { Carousel } from 'react-responsive-carousel';
// import sifuturo from '../../assets/images/Ricardo.png'

// import futurodigital from '../../assets/images/FUTURODIGITAL-sin-fondo.png'

const Fondo = () => {
    return ( 
      <div></div>
        //<Carousel className="caru" showArrows={false} showStatus={false} showIndicators={false} showThumbs={false} infiniteLoop={true} autoPlay={true} interval={10000} >
        // <div className='caru'>
        //   {/* <img alt="NO IMAGEN" height={window.screen.height-(window.screen.height/2)} src={sifuturo} /> */}
        // </div>
        
        /* <div >
          <img alt="NO IMAGEN" width="100%" height={window.screen.height-100} src={charpc} />          
        </div>
        <div >
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <img alt="NO IMAGEN" width="100%" height="150px" src={futurodigital} />          
        </div> */
     // </Carousel>
    )
}

export default Fondo
