import { dynamicWrapper } from 'react-router-guard';
import { Auth, AuthLogin, RestringirCliente } from './index'

export default[
    {
        path: '/calificacion',
        component: dynamicWrapper(() => import('../pages/Calificacion/Calificacion')),
    },
    {
        path: '/login',
        exact: true,
        canActivate: [AuthLogin],
        component: dynamicWrapper(() => import('../pages/Login/Login')),
    },
    {
        path:'/home/',
        canActivate: [Auth],
        component: dynamicWrapper(() => import('../pages/Menu/Menu')),
    },
    // ticket
    {
        path: '/detalle-ticket',
        canActivate: [Auth, RestringirCliente ],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Ticket/DetalleTicket/DetalleTicket')),
    },
    {
        path: '/generar-ticket',
        canActivate: [Auth,],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Ticket/GenerarTicket/GenerarTicket')),
    },
    {
        path: '/ticket',
        canActivate: [Auth,],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Ticket/Ticket')),
    },
    // cliente
    {
        path: '/cliente-detalle',
        canActivate: [Auth, RestringirCliente],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Cliente/ClienteDetalle')),
    },
    {
        path: '/contratos-cliente',
        canActivate: [Auth, RestringirCliente],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Cliente/ClienteContratos')),
    },
    {
        path:'/cliente',
        canActivate: [Auth, RestringirCliente],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Cliente/ClientesPage')),
    },
    // contrato
    {
        path: '/contrato',
        canActivate: [Auth, RestringirCliente],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Contrato/Contrato')),
    },
    // personal
    {
        path: '/personal-detalle',
        canActivate: [Auth, RestringirCliente],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Personal/Detalle')),
    },
    {
        path: '/personal',
        canActivate: [Auth, RestringirCliente],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Personal/Personal')),
    },
    //especialidad
    {
        path: '/especialidad-detalle',
        canActivate: [Auth, RestringirCliente],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Especialidad/Detalle')),
    },
    {
        path: '/especialidad',
        canActivate: [Auth, RestringirCliente],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Especialidad/Especialidad')),
    },
    // mantenimientos
    {
        path:'/mantenimientos/',
        canActivate: [Auth, RestringirCliente],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Mantenimientos/Mantenimientos')),
    }, 
    // repuestos
    {
        path: '/repuestos',
        canActivate: [Auth, RestringirCliente],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Inventario/Inventario')),
    },
    {
        path: '/reportes',
        canActivate: [Auth, RestringirCliente],
        exact: true,
        component: dynamicWrapper(() => import('../pages/Reporte/Reporte')),
    },
    // perfil
    {
        path: '/perfil/',
        canActivate: [Auth],
        exact: true,
        component: dynamicWrapper(() => import('../pages/PerfilCliente/PerfilCliente')),
    },
    {
        path: '/home/:valor',
        canActivate: [Auth],
        redirect:'/home'
    },
    {
        path: '/:valor',
        redirect: '/login',
    },
    {
        path: '/login/:valor',
        redirect: '/login',
    },
     {
        path: '/',
        redirect: '/login',
    },
  ];
